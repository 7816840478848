.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#container {
  pointer-events: none;
}

table {
  border: 1px solid black;
  padding: 10px;
  border-spacing: 2px;
  width: 100%;
  column-span: 10%;
}

tr {
  block-size: 50px
}

.box-inner {
  cursor: pointer;
  max-height: 75px;
  overflow-y: auto;
}

.box-inner::-webkit-scrollbar {
  width: 3px;
}

.box-inner::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid #4691ce;
}

.week-border {
  display: block;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #eaeaea;
}

.popUp {
  width: 193rem;
  padding-left: 30%;
  height: 112vh;
}

.grid-size {
  grid-template-columns: 0fr 1fr;
}

.popup-size {
  background-color: var(--tw-ring-offset-color);
  width: 20%;

}

.text-box {
  width: 95%;
  font-size: 22px;
}

.background-color {
  background-color: #ffff;
}

/* input:invalid {
  border: 2px solid red;
  animation: shake 300ms
}
select:invalid {
  border: 2px solid red;
  animation: shake 300ms
} */
.church {
  font-size: 25px;

}

.logo {
  font-size: 25px;

}

.p-3 {
  padding: 0.25rem;
}

.p-2 {
  padding: -0.5rem;
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: darkcyan;
  color: white;
  font-family: Verdana;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: sans-serif;
}

.px-4 {
  padding-left: 8px;
  padding-right: 8px;
  color: white;
}

.py-2 {
  padding-top: 0.5px;
  padding-bottom: 0.5px;
}

.Swal {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em;
}

.swal-button {
  background-color: #0a3c38;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.p-2 {
  padding: 0.5rem;
  padding-left: 50px;
}

.p-4 {
  padding: 0.25rem;
  padding-left: 50px;
  background-color: #1fd1c08a;
}

.p-3 {
  padding: 1.25rem;
  padding-left: 120px;
}

.p-6 {
  padding: 0.5rem;
}

.flex.items-center.justify-end.p-6.border-t.border-solid.border-blueGray-200.rounded-b {
  padding: revert;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
  height: inherit;
}

.mb-5 {
  margin-bottom: 0.25rem;
}

img,
video {
  height: auto;
  width: 50px;
}

img,
h1 {
  display: inline-block;
  vertical-align: middle;
  font-size: 25px;
  padding-right: 7px;
}